<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item :label="$t('index.username')" prop="name">
              <el-input :placeholder="$t('index.username')" v-model="formInline.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="seachFun" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetFun" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="addUser" type="primary" v-if="$store.state.pageAction.indexOf('device.add')> -1">
          {{ $t('device.add') }}
        </el-button>
      </template>
      <el-table
          :data="appData"
          :default-sort="{prop: 'date', order: 'descending'}"
          border
          stripe
          style="width: 100%"
      >
        <el-table-column :label="$t('order.account')" prop="account"></el-table-column>
        <el-table-column :label="$t('role.group')" prop="role_title"></el-table-column>
        <el-table-column :label="$t('device.vesting')" prop="client_name"></el-table-column>
        <el-table-column :label="$t('order.status')" prop="status_title"></el-table-column>
        <el-table-column :label="$t('role.last_login')" prop="last_login_at" width="160"></el-table-column>
        <el-table-column :label="$t('device.creation_time')" prop="create_at" width="160"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="280">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" size="small"
                       v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{ $t('device.edit') }}
            </el-button>
            <el-button @click="handleStatus(scope.row)" :type="scope.row.status === 1 ?'warning':'success'" size="small"
                       v-if="$store.state.pageAction.indexOf('device.status')> -1">
              {{ scope.row.status === 1 ? $t('role.disable') : $t('role.enable') }}
            </el-button>
            <el-button @click="resetCode(scope.row)" type="info" size="small"
                       v-if="$store.state.pageAction.indexOf('role.reset')> -1">{{ $t('role.reset') }}
            </el-button>
            <el-button @click="handleDelete(scope.row.uid)" type="danger" size="small"
                       v-if="$store.state.pageAction.indexOf('device.delete')> -1">{{ $t('device.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog center :title="$t('role.add')" top="30vh" v-model="addPop" width="30%">
        <el-form :model="addForm" ref="addForm" :rules="rules">
          <el-form-item :label="$t('order.account')" label-width="120px" prop="account">
            <el-input v-model="addForm.account"></el-input>
          </el-form-item>
          <el-form-item :label="$t('role.phone')" label-width="120px" prop="mobile">
            <el-input v-model="addForm.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('login.password')" label-width="120px" prop="password" v-if="addForm.uid==''">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.vesting')" label-width="120px" prop="client_id">
            <el-cascader
                :options="clientOpts"
                :placeholder="$t('device.vesting')"
                v-model="addForm.client_id"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('role.group')" label-width="120px" prop="role_id">
            <el-select :placeholder="$t('role.group')" v-model="addForm.role_id">
              <el-option
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-for="item in roleOpts">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('order.status')" label-width="120px" prop="status">
            <el-radio :label="1" v-model="addForm.status">{{ $t('role.enable') }}</el-radio>
            <el-radio :label="2" v-model="addForm.status">{{ $t('role.disable') }}</el-radio>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="addPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="handleSubmit" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog :center="true" :title="$t('role.change')" top="30vh" v-model="dialogFormVisible" width="30%">
        <el-form :model="resetform">
          <el-form-item :required="true" :label="$t('order.account')" label-width="80px">
            <el-input autocomplete="off" :disabled="true" v-model="resetform.name"></el-input>
          </el-form-item>
          <el-form-item :required="true" :label="$t('login.password')" label-width="80px">
            <el-input autocomplete="off" v-model="resetform.password"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="affirmReset" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20, 50]"
          :total="listCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          fixed="right"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import system from "../../model/system";
import role from "../../model/role";
import client from "../../model/client";

export default {
  name: "user",
  data() {
    return {
      dialogFormVisible: false,
      formInline: {
        name: "",
        page: 1,
        size: 10
      },
      resetform: {
        uid: "",
        name: "",
        password: ""
      },
      listCount: 0,
      appData: [],
      addPop: false,
      addForm: {
        uid: "",
        name: "",
        account: "",
        mobile: "",
        password: "",
        client_id: [],
        role_id: "",
        status: 1
      },
      roleOpts: [],
      clientOpts: [],
      rules: {
        account: [
          {required: true, message: this.$t('login.user_empty'), trigger: "blur"},
          {min: 3, max: 10, message: this.$t('login.user_length'), trigger: "blur"}
        ],
        client_id: [
          {required: true, message: this.$t('role.select_customer'), trigger: "blur"}
        ],
        role_id: [
          {required: true, message: this.$t('role.select_group'), trigger: "blur"}
        ]
      }
    };
  },
  async mounted() {
    await this.loadData();
    this.loadRole();
    this.loadClient();
  },
  methods: {
    async loadData() {
      const ret = await system.user(this.formInline)
      if (ret.code === 200) {
        this.appData = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    async loadRole() {
      const ret = await role.map({})
      if (ret.code === 200) {
        ret.data.forEach(v => {
          this.roleOpts.push({
            value: v.id,
            label: v.title
          })
        })
      }
    },
    async loadClient() {
      const ret = await client.list({});
      if (ret.code === 200) {
        ret.data.forEach(i => {
          let options = {
            value: i.id,
            label: i.name,
            children: []
          };
          if (i.children.length > 0) {
            this.clientOpts.push({
              value: i.id,
              label: i.name
            });
            i.children.forEach(v => {
              let opts = {
                value: v.id,
                label: v.name
              };
              if (v.children.length > 0) {
                options.children.push({
                  value: v.id,
                  label: v.name
                });
                opts.children = [];
                v.children.forEach(item => {
                  let children = {
                    value: item.id,
                    label: item.name,
                  };
                  if (item.children.length > 0) {
                    children.children = [];
                    item.children.forEach(val => {
                      children.children.push({
                        value: val.id,
                        label: val.name
                      });
                    })
                  }
                  opts.children.push(children);
                });
              }
              options.children.push(opts);
            });
          }
          this.clientOpts.push(options);
        });
      }
    },
    async handleSubmit() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.addForm))
          params.name = params.account
          params.client_id = params.client_id.pop()
          const ret = await system.operate(params)
          if (ret.code === 200) {
            this.addPop = false
            this.$message.success(ret.message)
            this.loadData()
          } else {
            this.$message.warning(ret.message)
          }
        } else {
          return false;
        }
      });
    },
    async handleStatus(row) {
      const ret = await system.status({
        uid: row.uid,
        status: row.status == 1 ? 2 : 1
      })
      if (ret.code === 200) {
        this.$message.success(ret.message)
        this.loadData()
      } else {
        this.$message.warning(ret.message)
      }
    },
    resetCode(row) {
      this.dialogFormVisible = true;
      this.resetform.uid = row.uid;
      this.resetform.name = row.name;
    },
    async affirmReset() {
      const ret = await system.reset(this.resetform);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.dialogFormVisible = false;
      } else {
        this.$message.error(ret.message);
      }
    },
    seachFun() {
      this.loadData();
    },
    resetFun() {
      this.formInline.name = ''
      this.loadData();
    },
    addUser() {
      this.addPop = true;
      setTimeout(() => {
        this.$refs.addForm.resetFields()
        this.addForm = {
          uid: "",
          name: "",
          account: "",
          password: "",
          client_id: "",
          role_id: "",
          status: 1
        }
      })
    },
    handleDelete(uid) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await system.delete({
              uid: uid
            });
            if (ret.code === 200) {
              this.$message.success(this.$t('device.delete_success'));
              this.loadData();
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    handleEdit(row) {
      this.addPop = true;
      setTimeout(() => {
        this.$refs.addForm.resetFields()
        this.addForm = JSON.parse(JSON.stringify(row))
        this.clientOpts.forEach(v => {
          if (this.addForm.client_id == v.value) {
            this.addForm.client_id = [v.value]
            return
          }
          if (v.children) {
            v.children.forEach(i => {
              if (this.addForm.client_id == i.value) {
                this.addForm.client_id = [v.value, i.value]
                return
              }
              if (i.children) {
                i.children.forEach(val => {
                  if (this.addForm.client_id == val.value) {
                    this.addForm.client_id = [v.value, i.value, val.value]
                    return
                  }
                })
              }
            })
          }
        })
      })
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
