import request from '../utils/request'

export default {
  async user (data) {   // 获取管理用户列表
    return await request('/v1/system/user', data, 'POST')
  },
  async operate (data) {    // 新增/修改用户信息
    return await request('/v1/system/operate', data, 'POST')
  },
  async reset (data) {  // 重置密码
    return await request('/v1/system/reset', data, 'POST')
  },
  async status (data) {  // 修改用户状态
    return await request('/v1/system/status', data, 'POST')
  },
  async delete (data) {  // 修改用户状态
    return await request('/v1/system/delete', data, 'POST')
  }
}
